import React, { useState } from "react";
import "../CSS/paymentCard.css";
import "../CSS/register.css";

export default function PaymentCard(props) {
    return(
        <div role="button" >
          <button className="box" tabIndex={0} onClick={e => {
            e.preventDefault();
            document.cookie = `subType = ${props.months}`
            }}>
        <div className="description">
          <h1>{props.months} Month Access</h1>
          <h2>&#10003; Full Access to revision and viva modes</h2>
          <h2>&#10003; Track progress</h2>
          <h2>&#10003; Random and specific topic selection</h2>
          <h3 className ="price">{props.price}</h3>
        </div>
        </button>
        </div>
    )
}