import React, { useState } from "react";
import { TextField, Button, Divider } from "@material-ui/core";
import "./CSS/register.css";
import logo from "../assets/logo/logo.png";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { Link } from "react-router-dom";
import PaymentCard from "./Components/PaymentCard";
import { BackendURL } from "../backendURL";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2c1951",
    },
    secondary: {
      main: "#1c1c1e",
    },
  },
});
const radios = [
  { name: "Pay Now", value: "1" },
  { name: "Pay Later", value: "2" },
];

export default function Register({ history }) {
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const validatePassword = (password) => {
    const re = /^(?=.*[0-9])(?=.{8,})/;
    return re.test(String(password));
  };
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
  });
  const [billing, setBilling] = useState({
    line1: "",
    line2: "",
    post: "",
    city: "",
  });
  const [text, setText] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorMatching, setErrorMatching] = useState("");
  const [allow, setAllow] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [payNow, setPayNow] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setText("Loading...");
    if (validateEmail(details.email)) {
      if (validatePassword(details.password)) {
        if (allow) {
          setErrorPassword("");
          await axios
            .post(`${BackendURL}/registerTest`, {
              firstName: details.fname,
              lastName: details.lname,
              email: String(details.email).toLowerCase(),
              password: details.password,
            })
            .then(async (response) => {
              console.log(response);
              await axios
                .post(`${BackendURL}/addBillingTest`, {
                  address: {
                    city: billing.city,
                    country: "GB",
                    line1: billing.line1,
                    line2: billing.line2,
                    postal_code: billing.post,
                  },
                  customerID: response.data,
                })
                .then(async (res) => {
                  console.log(res);
                  axios
                    .post(
                      `${BackendURL}/create-payment-intent`,
                      { stripeID: response.data },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then(({ data }) => {
                      history.push({
                        pathname: "/payment",
                        state: {
                          clientSecret: data.client_secret,
                          intentID: data.id,
                          history: history,
                        },
                      });
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((error) => {
              if (error.response.status == 302) {
                console.log("exists");
                setText("Email already exists");
                setEmailExists(true);
              }
            });
        } else {
          setText("");
        }
      } else {
        setText("");
        setErrorPassword(
          "Invalid Password - Must be at least 8 characters and contain both letters and numbers"
        );
      }
      setErrorEmail("");
    } else {
      if (!validatePassword(details.password)) {
        setErrorPassword(
          "Invalid Password - Must be at least 8 characters and contain both letters and numbers"
        );
      }
      setText("");
      setErrorEmail("Invalid Email");
    }
  };
  const handlePayLater = async (e) => {
    e.preventDefault();
    setText("Loading...");
    if (validateEmail(details.email)) {
      if (validatePassword(details.password)) {
        if (allow) {
          setErrorPassword("");
          await axios
            .post(`${BackendURL}/register`, {
              firstName: details.fname,
              lastName: details.lname,
              email: String(details.email).toLowerCase(),
              password: details.password,
            })
            .then(async (response) => {
              console.log(response);
              await axios
                .post(`${BackendURL}/addBilling`, {
                  address: {
                    city: billing.city,
                    country: "GB",
                    line1: billing.line1,
                    line2: billing.line2,
                    postal_code: billing.post,
                  },
                  customerID: response.data,
                })
                .then(async (res) => {
                  console.log(res);
                  history.push({
                    pathname: "/success",
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((error) => {
              if (error.response.status == 302) {
                console.log("exists");
                setText("Email already exists");
                setEmailExists(true);
              }
            });
        } else {
          setText("");
        }
      } else {
        setText("");
        setErrorPassword(
          "Invalid Password - Must be at least 8 characters and contain both letters and numbers"
        );
      }
      setErrorEmail("");
    } else {
      if (!validatePassword(details.password)) {
        setErrorPassword(
          "Invalid Password - Must be at least 8 characters and contain both letters and numbers"
        );
      }
      setText("");
      setErrorEmail("Invalid Email");
    }
  };
  return (
    <div className="fullBody">
      <div className="container">
        {/* <img src={logo} alt="Logo" className="logo"></img> */}
        <form className="formContainer">
          <div className="payment-cards-register">
            <PaymentCard months={1} price={"£16"} />
            <PaymentCard months={2} price={"£26"} />
            <PaymentCard months={3} price={"£36"} />
            <PaymentCard months={6} price={"£50"} />
            <PaymentCard months={12} price={"£80"} />
            <PaymentCard months={24} price={"£120"} />
          </div>
          <i>Payment is on the next page</i>
          <label className="label">Login Details</label>
          <Divider light={false} variant="fullWidth" />
          <div className="oneLine">
            <TextField
              id="first"
              label="First Name"
              variant="outlined"
              required
              className="field"
              margin="normal"
              fullWidth
              onChange={(e) => {
                setDetails({
                  fname: e.target.value,
                  lname: details.lname,
                  email: String(details.email).toLowerCase(),
                  password: details.password,
                });
              }}
            />
            <div className="spacing"></div>
            <TextField
              id="last"
              label="Last Name"
              variant="outlined"
              required
              margin="normal"
              fullWidth
              onChange={(e) => {
                setDetails({
                  fname: details.fname,
                  lname: e.target.value,
                  email: String(details.email).toLowerCase(),
                  password: details.password,
                });
              }}
            />
          </div>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            required
            margin="normal"
            onChange={(e) => {
              setDetails({
                fname: details.fname,
                lname: details.lname,
                email: e.target.value.toLowerCase(),
                password: details.password,
              });
            }}
          />
          <h5>{errorEmail}</h5>
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            required
            margin="normal"
            type="password"
            onChange={(e) => {
              setDetails({
                fname: details.fname,
                lname: details.lname,
                email: String(details.email).toLowerCase(),
                password: e.target.value,
              });
            }}
          />
          <h5>{errorPassword}</h5>
          <TextField
            id="password"
            label="Verify Password"
            variant="outlined"
            required
            margin="normal"
            type="password"
            onChange={(e) => {
              if (e.target.value != details.password) {
                setErrorMatching("Passwords Don't Match");
                setAllow(false);
              } else {
                setErrorMatching("");
                setAllow(true);
              }
            }}
          />
          <h5>{errorMatching}</h5>
          <br />
          <br />
          <label className="label">Billing Details</label>
          <Divider light={false} variant="fullWidth" />
          <TextField
            id="address1"
            label="Address line 1"
            variant="outlined"
            required
            className="field"
            margin="normal"
            fullWidth
            onChange={(e) => {
              setBilling({
                line1: e.target.value,
                line2: billing.line2,
                post: billing.post,
                city: billing.city,
              });
            }}
          />
          <TextField
            id="address2"
            label="Address line 2"
            variant="outlined"
            className="field"
            margin="normal"
            fullWidth
            onChange={(e) => {
              setBilling({
                line1: billing.line1,
                line2: e.target.value,
                post: billing.post,
                city: billing.city,
              });
            }}
          />
          <TextField
            id="postcode"
            label="Post Code"
            variant="outlined"
            className="field"
            margin="normal"
            required
            fullWidth
            onChange={(e) => {
              setBilling({
                line1: billing.line1,
                line2: billing.line2,
                post: e.target.value,
                city: billing.city,
              });
            }}
          />
          <TextField
            id="city"
            label="City"
            variant="outlined"
            className="field"
            margin="normal"
            required
            fullWidth
            onChange={(e) => {
              setBilling({
                line1: billing.line1,
                line2: billing.line2,
                post: billing.post,
                city: e.target.value,
              });
            }}
          />
          {/* <ToggleButtonGroup
            value={1}
            exclusive
            // onChange={}
            aria-label="text alignment"
          >
            <ToggleButton value="left" aria-label="left aligned">
              Pay Now
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered">
              Pay Later
            </ToggleButton>
          </ToggleButtonGroup> */}
          <ThemeProvider theme={theme}>
            <div className="buttonContainer">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="submit"
                margin="normal"
                fullWidth
              >
                Pay Now
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePayLater}
                type="submit"
                margin="normal"
                fullWidth
              >
                Pay Later
              </Button>
            </div>
          </ThemeProvider>

          {text}
          {emailExists && (
            <div>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <h4>Click Here To Login!</h4>
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
