import React from "react";
import "../App.css";

import "./CSS/register.css";

function FAQ({ history }) {
  return (
    <div className="Privacy">
      <h1>Frequently Asked Questions</h1>
      <br />
      <b>Is FRCA Reveal designed for the Primary FRCA written exam?</b>
      <ul>
        <li>
          FRCA Reveal is designed primarily for the Primary FRCA SOE and OSCE
          exams
        </li>
        <li>
          The content is presented in a question / answer style, so some people
          have found it helpful to get some context for the written exam
        </li>
      </ul>
      <br />
      <b>What stage of the Final is FRCA Reveal designed for?</b>
      <ul>
        <li>
          FRCA Reveal most closely mirrors the short cases of the Structured
          Oral Exam
        </li>
        <li>
          However, the content is laid out so as to serve as basic revision
          notes, and a framework with which to answer Constructed Response
          Questions.
        </li>
        <li>
          There are also key facts and figures for many subjects, which would
          lend themselves well to multiple choice questions.
        </li>
      </ul>

      <br />
      <b>Is this everything I need to know for the Final?</b>
      <ul>
        <li>Of course not! </li>
        <li>
          For the Final FRCA, a broad base of knowledge, and a deeper
          understanding of the underlying theory is required. FRCA Reveal aims
          to present the key points of each subject, which should be included in
          an ideal exam answer.{" "}
        </li>
        <li>
          FRCA Reveal demonstrates a structure with which to approach any
          question{" "}
        </li>
        <li>
          We are keen to hear feedback and suggestions for more subtopics-
          either feedback via the app, or email{" "}
          <a href="mailto:frca-reveal@gmail.com ">frca-reveal@gmail.com</a>
        </li>
      </ul>

      <br />
      <b>How does the app work?</b>
      <ul>
        <li>
          All material is accessed via the App, which should be downloaded from
          the App Store/Google Play
        </li>
        <li>
          Registration and payment is online initially, and then the details can
          be used to login to the app
        </li>
      </ul>
      <br />
      <b>How is payment handled?</b>
      <ul>
        <li>
          All payment is handled through Stripe, the online payment platform
          used by lots of companies, including Deliveroo
        </li>
        <li>
          Stripe handle all card details, which are fully secure and encrypted
        </li>
      </ul>
      <br />
      <b>Is Wifi/data required?</b>
      <ul>
        <li>
          Each time it is opened, the FRCA Reveal app will make contact with the
          content database to update any new content, so a minimal amount of
          data will be used (the app itself will not need updating each time)
        </li>
        <li>
          After this initial download, the FRCA Reveal can be used offline,
          including concurrently with other apps as long as it is still running
          in the background
        </li>
      </ul>
    </div>
  );
}

export default FAQ;
