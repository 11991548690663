import React from "react";
import screen6 from "../assets/Screenshot/screen6.jpeg";
import ReactMarkdown from "react-markdown";

export default function About() {
  const bg = `FRCA Reveal is the culmination of our combined experience of running FRCA exam courses for the last ten years. Well-prepared candidates fail these exams for three reasons: 
    \\
    - **_Early-onset panic:_** the examiner’s opening question is met with an embarrassing period of silence as the candidate’s brain struggles to understand what has just been asked and work out a reasonable answer. The candidate’s confidence is knocked, and the exam never recovers.
    - **_Disorganised answers:_** the candidate knows enough about a topic, but the answer is a mess of jumbled up words, totally lacking structure.
    - **_Late-onset panic:_** (the least common) the candidate gets through the initial couple of minutes well but cannot cope when the viva moves on to a different aspect of the topic.
   \\
    We wanted to create an app closely aligned with the RCoA curriculum, which incorporates the techniques we have developed on our revision courses to help candidates overcome these barriers to passing the exam:
    \\
    - Short, authoritative, snappy opening statements: these can be learned and practiced. When you hear the examiner say the word “acromegaly” in the opening question, you can immediately trot out your pre-prepared “acromegaly is a neuroendocrine disorder of the anterior pituitary gland” whilst your subconscious works out what the examiner actually asked. No awkward silences.
    - Well-structured answers to questions: all the important classifications that the examiner is expecting to hear. By following the various structures laid out (e.g. history-examination-investigations, preoperative-intraoperative-postoperative), the examiner should have nothing to do but just tick their marksheet!
    - Directions the viva could take: practicing the most common directions that the viva could take removes some of the anxiety of the second half of the viva.
`;
  const how = `
The app is designed to allow work in two ways: ‘revision’ mode allows solo work, whereas ‘viva’ mode is designed to allow others to test you, replicating the viva.
\\
− **_Revision mode:_** you can select a particular topic, or a generate a random topic. The content of each heading is "revealed" simply by pressing on that heading. That way, you can use this mode either to self-test (using the headings as a prompt) or, if the topic is new to you, as a basic introduction to that area of anaesthesia. For the Final FRCA, there are links between clinical short cases and related basic science topics, to replicate the format of the RCoA exam.
− **_Viva mode:_** this mode is designed to replicate an exam. The content is the same, but the headings are framed as a question. This allows someone to act as an examiner (including someone non-medical).
- **_Filter:_** you can tick off topics that you're comfortable with, and filter them out by clicking the tick at the bottom of the screen.

`;
  const tips = `
It is potentially daunting to act as an examiner for a friend or family member doing an exam, particularly those from a non-anaesthetic or non-medical background. However, your offer of help will be invaluable and much appreciated, even if you are unfamiliar with the format of the exam. The vast bulk of the benefit to them will be verbalising their understanding of the topic, and by asking them the question and putting them on the spot, this will be an excellent test of their knowledge.
\\
The following tips may be useful, particularly to those not used to medical exams:
1. Stick to the question. They can ask to repeat the question, but the questions provided should be clear enough without you needing to reframe it.
2. Don’t interrupt. The candidate should frame their answers to be concise, and have a natural ending, after which you can ask the next question.
3. Don’t worry about correcting a candidate if you think they have made a mistake. The ‘real’ examiners will be able to notice even small inaccuracies in the answer and can cross-examine further (the classic response to an incorrect statement is “are you sure?”!). However, the candidates can make some mistakes and still receive a pass mark. Most candidates will know if they have said something significantly incorrect, or have gaps in their knowledge. For revision purposes, it may be useful to make a note of the point you think is incorrect and discuss it afterwards.
4. The ‘directions the viva may take’ are follow on questions- these are typically arrived at when the candidate is answering well.
5. Keep in mind nerves. Many candidates find that being examined by friends or especially colleagues is actually more daunting than the real thing, so it is common to ‘draw a blank’. Simply repeat the question, don’t rush them. More often than not the answer will come to them.`;

  return (
    <div className="about">
      <div className="cards">
        <div className="profile-how">
          <div className="profile-text">
            <h4>Background</h4>
            FRCA Reveal is the culmination of our combined experience of running
            FRCA exam courses for the last ten years. Well-prepared candidates
            fail these exams for three reasons:
            <br />
            <br />
            - Early-onset panic: the examiner’s opening question is met with an
            embarrassing period of silence as the candidate’s brain struggles to
            understand what has just been asked and work out a reasonable
            answer. The candidate’s confidence is knocked, and the exam never
            recovers.
            <br />
            - Disorganised answers: the candidate knows enough about a topic,
            but the answer is a mess of jumbled up words, totally lacking
            structure.
            <br />- Late-onset panic: (the least common) the candidate gets
            through the initial couple of minutes well but cannot cope when the
            viva moves on to a different aspect of the topic.
            <br />
            <br />
            We wanted to create an app closely aligned with the RCoA curriculum,
            which incorporates the techniques we have developed on our revision
            courses to help candidates overcome these barriers to passing the
            exam:
            <br />
            <br />
            - Short, authoritative, snappy opening statements: these can be
            learned and practiced. When you hear the examiner say the word
            “acromegaly” in the opening question, you can immediately trot out
            your pre-prepared “acromegaly is a neuroendocrine disorder of the
            anterior pituitary gland” whilst your subconscious works out what
            the examiner actually asked. No awkward silences.
            <br />
            - Well-structured answers to questions: all the important
            classifications that the examiner is expecting to hear. By following
            the various structures laid out (e.g.
            history-examination-investigations,
            preoperative-intraoperative-postoperative), the examiner should have
            nothing to do but just tick their marksheet!
            <br />- Directions the viva could take: practicing the most common
            directions that the viva could take removes some of the anxiety of
            the second half of the viva.
            <br />
            <br />
            <br />
            <h4>How To Use</h4>
            The app is designed to allow work in two ways: ‘revision’ mode
            allows solo work, whereas ‘viva’ mode is designed to allow others to
            test you, replicating the viva.
            <br />
            <br />
            − Revision mode: you can select a particular topic, or a generate a
            random topic. The content of each heading is "revealed" simply by
            pressing on that heading. That way, you can use this mode either to
            self-test (using the headings as a prompt) or, if the topic is new
            to you, as a basic introduction to that area of anaesthesia. For the
            Final FRCA, there are links between clinical short cases and related
            basic science topics, to replicate the format of the RCoA exam.
            <br />− Viva mode: this mode is designed to replicate an exam. The
            content is the same, but the headings are framed as a question. This
            allows someone to act as an examiner (including someone
            non-medical).
            <br /> - Filter: you can tick off topics that you're comfortable
            with, and filter them out by clicking the tick at the bottom of the
            screen.
            <br />
            <br />
            <br />
            <h4>A few more tips</h4>
            It is potentially daunting to act as an examiner for a friend or
            family member doing an exam, particularly those from a
            non-anaesthetic or non-medical background. However, your offer of
            help will be invaluable and much appreciated, even if you are
            unfamiliar with the format of the exam. The vast bulk of the benefit
            to them will be verbalising their understanding of the topic, and by
            asking them the question and putting them on the spot, this will be
            an excellent test of their knowledge.
            <br />
            The following tips may be useful, particularly to those not used to
            medical exams:
            <br />
            <br />
            1. Stick to the question. They can ask to repeat the question, but
            the questions provided should be clear enough without you needing to
            reframe it.
            <br />
            2. Don’t interrupt. The candidate should frame their answers to be
            concise, and have a natural ending, after which you can ask the next
            question.
            <br />
            3. Don’t worry about correcting a candidate if you think they have
            made a mistake. The ‘real’ examiners will be able to notice even
            small inaccuracies in the answer and can cross-examine further (the
            classic response to an incorrect statement is “are you sure?”!).
            However, the candidates can make some mistakes and still receive a
            pass mark. Most candidates will know if they have said something
            significantly incorrect, or have gaps in their knowledge. For
            revision purposes, it may be useful to make a note of the point you
            think is incorrect and discuss it afterwards.
            <br />
            4. The ‘directions the viva may take’ are follow on questions- these
            are typically arrived at when the candidate is answering well.
            <br />
            5. Keep in mind nerves. Many candidates find that being examined by
            friends or especially colleagues is actually more daunting than the
            real thing, so it is common to ‘draw a blank’. Simply repeat the
            question, don’t rush them. More often than not the answer will come
            to them.`
          </div>
        </div>
      </div>

      <div className="cards">
        <div className="profile">
          <div className="profile-text">
            <h4>Dr David Chambers BM BCh MChem DPhil MRCP FRCA PgDipMedEd</h4>
            Dave is a Consultant Neuroanaesthetist and College Tutor at Salford
            Royal Hospital. He spent ten years co-organising the North West
            Final FRCA exam course, and is the author of two key textbooks for
            the FRCA exams: 'Basic Physiology for Anaesthetists' (now in its
            second edition) and 'CRQs for the Final FRCA'.
          </div>
          <div>
            <img
              src="https://i.ibb.co/JrGjMsY/Dave-photo-cropped-2.jpg"
              className="profile-img"
            />
          </div>
        </div>
        <div className="profile">
          <div className="profile-text">
            <h4>Dr Thomas Mount MBChB BSc FRCA PgCertMedEd</h4>
            Tom is a consultant anaesthetist and Final FRCA lead at Brighton &
            Sussex University Hospitals, runs the Final FRCA BSUH Written
            Courses, and is a lead faculty member of the Bright Course Final
            FRCA viva course.
          </div>
          <div>
            <img
              src="https://i.ibb.co/3ykTRbn/Tom-photo-2.jpg"
              className="profile-img"
            />
          </div>
        </div>
        <div className="profile">
          <div className="profile-text">
            <h4>
              Sam Kinloch Large Full Stack Developer currently studying
              Mechanical Engineering
            </h4>
            Sam is a full stack developer currently studying at the University
            of Leeds for a Master in Mechanical Engineering. His website is
            <br />
            <a href="https://kl-webdesign.co.uk/">
              https://kl-webdesign.co.uk/
            </a>
          </div>
          <div>
            <img
              src="https://i.ibb.co/sJfmdK4/IMG-2781.jpg"
              className="profile-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
