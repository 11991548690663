import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import "../CSS/register.css";
import axios from "axios";
import { TextField, Button, Divider } from "@material-ui/core";
import pbs from "../../assets/pbsB.png";
import PaymentCard from "./PaymentCard";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackendURL } from "../../backendURL";
export default function CheckoutForm(props) {
  const theme = createTheme({
    disabledButton: {
      backgroundColor: "grey",
    },
    palette: {
      primary: {
        main: "#2c1951",
      },
      secondary: {
        main: "#f2f2f7",
      },
    },
  });

  let [currentSelected, setCurrentSelected] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  const [text, setText] = useState("");
  const [promo, setPromo] = useState("");
  const [promoID, setPromoID] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [percent, setPercent] = useState(0);
  const [promoError, setPromoError] = useState("");

  const handleSubmit = async (event) => {
    //console.log(props.location.state.customerID);
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const clientSecret = props.location.state.clientSecret;
    if (!clientSecret) {
      return;
    }
    sendPaymentRequest(props.location.state.intentID, clientSecret);
  };
  const handlePromo = async (e) => {
    e.preventDefault();
    await axios
      .post(`${BackendURL}/get-promo-discount`, {
        code: promo,
      })
      .then((response) => {
        setPromoID(response.data.id);
        setPercent(response.data.percent);
        setPromoError("Promo Code Applied");
      })
      .catch((err) => {
        setPromoError("Invalid Promo Code");
      });
  };

  const sendPaymentRequest = async (intentID, token) => {
    setText("Please wait - Do not press twice as you will be charged twice");
    setButtonEnabled(false);
    if (currentSelected && currentSelected > 0) {
      axios
        .post(`${BackendURL}/get-price`, {
          discount: percent,
          months: currentSelected,
        })
        .then((returnedPrice) => {
          console.log(returnedPrice);
          axios
            .post(`${BackendURL}/update-payment-intent`, {
              intentID: intentID,
              amount: returnedPrice.data.price,
            })
            .then((updatedPaymentIntent) => {
              console.log(updatedPaymentIntent);
              stripe
                .confirmCardPayment(token, {
                  payment_method: {
                    card: elements.getElement(CardElement),
                  },
                })
                .then(function (result) {
                  if (result.paymentIntent) {
                    if (result.paymentIntent.status === "succeeded") {
                      props.location.state.history.push({
                        pathname: "/processing",
                        state: {
                          clientSecret: token,
                          subType: currentSelected,
                          history: props.location.state.history,
                          customerID: updatedPaymentIntent.data.customer,
                        },
                      });
                    }
                  } else {
                    console.log(result.error);
                    setText(result.error.message);
                    setButtonEnabled(true);
                  }
                });
            });
        })
        .catch((err) => {
          console.log(err);
        });

      // get token from handle submit
      // get customer id from handlesubmit
      // TODO: get payment intent from handle submit
      // create payment request
      //PAYMENT REQUEST
      /*
      
      => Add Payment Method
      => Attach new price to Payment Intent
      => Attach Payment Method to Intent
      => Confirm Payment - response
      => Payment Processing - repeat check confirm
      => Send Success or Failure
      */
    } else {
      setButtonEnabled(true);
      setText("Please select a package");
    }
  };

  function listenCookieChange(callback, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  }

  listenCookieChange(({ oldValue, newValue }) => {
    setCurrentSelected(
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("subType"))
        .split("=")[1]
    );
  }, 1000);

  return (
    <div className="fullBody">
      <div className="container">
        <div className="payment-cards">
          <PaymentCard
            months={1}
            price={"£" + parseFloat(16 * (1 - percent / 100)).toFixed(2)}
          />
          <PaymentCard
            months={2}
            price={"£" + parseFloat(26 * (1 - percent / 100)).toFixed(2)}
          />
          <PaymentCard
            months={3}
            price={"£" + parseFloat(36 * (1 - percent / 100)).toFixed(2)}
          />
          <PaymentCard
            months={6}
            price={"£" + parseFloat(50 * (1 - percent / 100)).toFixed(2)}
          />
          <PaymentCard
            months={12}
            price={"£" + parseFloat(80 * (1 - percent / 100)).toFixed(2)}
          />
          <PaymentCard
            months={24}
            price={"£" + parseFloat(120 * (1 - percent / 100)).toFixed(2)}
          />
        </div>

        <form onSubmit={handleSubmit} className="formContainer">
          <Divider />
          <b>
            {currentSelected
              ? "You have selected the " + currentSelected + " month package"
              : "Please select a package"}{" "}
          </b>
          <br />
          <br />
          <TextField
            id="promo"
            label="Promo Code"
            variant="outlined"
            margin="normal"
            onChange={(e) => {
              setPromo(e.target.value);
            }}
          />
          <h5>{promoError}</h5>
          <ThemeProvider theme={theme}>
            <div className="inlineButton">
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePromo}
                margin="small"
                size="small"
                fullWidth
              >
                Apply
              </Button>
            </div>
          </ThemeProvider>

          <br />
          <CardSection />

          <button className="button" disabled={!stripe || !buttonEnabled}>
            CONFIRM
          </button>
        </form>
        {text}
        <div className="pbsDiv">
          <a href="https://stripe.com/">
            <img src={pbs} className="pbs" alt="Powered by Stripe"></img>
          </a>
        </div>
      </div>
    </div>
  );
}
