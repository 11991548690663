import React, { useState } from "react";

export default function Error(props) {
  return (
    <div className="App">
      <div className="payment-processing">
        <div className="error-icon">!</div>
        <h7>
          {props.location.state.status + "  -  " + props.location.state.message}
        </h7>
      </div>
    </div>
  );
}
