import React, { useState, useEffect } from "react";
import { TextField, Button, Divider } from "@material-ui/core";
import "./CSS/register.css";
import logo from "../assets/logo/logo.png";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { BackendURL } from "../backendURL";

export default function Login({ history }) {
  const handleForgot = () => {
    if (details.email != "") {
      axios
        .post(`${BackendURL}/forgotEmail`, {
          email: details.email,
        })
        .then((resp) => {
          //console.log(resp);
          setText("Please check your email to change password");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setText("Enter an Email");
    }
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  useEffect(() => {
    if (getCookie("accessToken")) {
      history.push({ pathname: "/" });
    }
  }, []);
  const [details, setDetails] = useState({
    email: "",
    pass: "",
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2c1951",
      },
      secondary: {
        main: "#f2f2f7",
      },
    },
  });
  const [text, setText] = useState("");

  const handleLogin = async () => {
    setText("Loading...");
    await axios
      .post(`${BackendURL}/login`, {
        email: details.email,
        password: details.pass,
      })
      .then((response) => {
        Cookies.set("accessToken", response.data.accessToken, {
          expires: 1000,
        });

        history.push({ pathname: "/account" });
        window.location.reload();
      })
      .catch((error) => {
        setText("Incorrect Email or Password");
      });
  };

  return (
    <div className="fullBody">
      <div className="container">
        {/* <img src={logo} alt="Logo" className="logo"></img> */}
        <form className="formContainer">
          <label className="label">Login</label>
          <Divider light={false} variant="fullWidth" />
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            required
            margin="normal"
            onChange={(e) => {
              setDetails({
                email: e.target.value,
                pass: details.pass,
              });
            }}
          />
          <TextField
            id="pass"
            label="Password"
            variant="outlined"
            required
            margin="normal"
            type="password"
            onChange={(e) => {
              setDetails({
                email: details.email,
                pass: e.target.value,
              });
            }}
          />
          <ThemeProvider theme={theme}>
            <div className="buttonContainer">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleForgot}
                margin="normal"
                fullWidth
              >
                Forgot Password?
              </Button>
            </div>
            <div className="buttonContainer">
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                margin="normal"
                fullWidth
              >
                Login
              </Button>
            </div>
          </ThemeProvider>
          {text}
        </form>
      </div>
    </div>
  );
}
