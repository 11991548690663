import React, { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { BackendURL } from "../backendURL";

export default function PaymentProcessing(props) {
  const stripe = useStripe();

  useEffect(() => {
    const handleSubscription = async () => {
      stripe
        .retrievePaymentIntent(props.location.state.clientSecret)
        .then(async (response) => {
          if (
            response.paymentIntent &&
            response.paymentIntent.status === "succeeded"
          ) {
            console.log(response);
            axios
              .post(`${BackendURL}/update-sub`, {
                subType: props.location.state.subType,
                stripeID: props.location.state.customerID,
              })
              .then((response) => {
                if (response.status === 200) {
                  props.location.state.history.push("/success");
                } else {
                  props.location.state.history.push({
                    pathname: "/error",
                    state: {
                      status: response.status,
                      message: "SERVER ERROR",
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                props.location.state.history.push({
                  pathname: "/error",
                  state: {
                    status: err.status,
                    message: "SERVER ERROR",
                  },
                });
              });
          } else {
            props.location.state.history.push({
              pathname: "/error",
              state: {
                status: response.paymentIntent.status,
                message: "STRIPE ERROR",
              },
            });
          }
        });
    };
    handleSubscription();
  }, []);
  return (
    <div className="App">
      <div className="payment-processing">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h4>PAYMENT PROCESSING - PLEASE WAIT...</h4>
      </div>
    </div>
  );
}
