import React, { useEffect } from "react";
import "./App.css";
import Home from "./Screens/Home";
import Register from "./Screens/Register";
import Payment from "./Screens/Payment";
import Success from "./Screens/Success";
import UpdateSuccess from "./Screens/UpdateSuccess";
import About from "./Screens/About";
import { HashRouter, Route, Link } from "react-router-dom";
import Forgot from "./Screens/Forgot";
import DeleteSuccess from "./Screens/DeleteSuccess";
import Privacy from "./Screens/Privacy";
import FAQ from "./Screens/FAQ";
import logo from "./assets/logo/logo.png";
import Login from "./Screens/Login";
import Account from "./Screens/Account";
import PaymentReWork from "./Screens/PaymentReWork";
import PaymentProcessing from "./Screens/PaymentProcessing";
import Error from "./Screens/Error";
import { TextField, Button, Divider } from "@material-ui/core";

function Nav() {
  useEffect(() => {
    console.log("reloaded");
  });
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  return (
    <div className="navContainer">
      <img src={logo} className="logo" alt="logo"></img>

      <div className="navLinkCont">
        <ul>
          <li>
            <Link to="/" className="navLink">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" className="navLink">
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/faq" className="navLink">
              FAQ
            </Link>
          </li>
          <li>
            <Link
              to={getCookie("accessToken") ? "/account" : "/login"}
              className="navLink"
            >
              {getCookie("accessToken") ? "ACCOUNT" : "LOGIN"}
            </Link>
          </li>
          <li>
            <Link to="/register" className="navLink">
              REGISTER
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="contactContainer">
      <Divider light={false} variant="fullWidth" />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link to="/privacy" className="contactLink">
            PRIVACY
          </Link>
          <br />
          <Link to="/faq" className="contactLink">
            FAQ
          </Link>
          <br />
          <a href="mailto:frca.reveal@gmail.com" className="contactLink">
            CONTACT
          </a>
        </div>
        <div
          style={{ color: "#462a7b", fontWeight: "500", fontStyle: "italic" }}
        >
          Reveal Medical Apps Ltd.
          <br />
          Company number: 23895202
          <br />
          FRCA.reveal@gmail.com
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App-Container">
      <HashRouter basename="/">
        <Nav />
        <Route path="/processing" exact component={PaymentProcessing} />
        <Route path="/error" exact component={Error} />
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/payment" exact component={Payment} />
        <Route path="/about" exact component={About} />
        <Route path="/success" exact component={Success} />
        <Route path="/updatesuccess" exact component={UpdateSuccess} />
        <Route path="/account" exact component={Account} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/faq" exact component={FAQ} />
        <Route path="/forgot" exact component={Forgot} />
        <Route path="/deletesuccess" exact component={DeleteSuccess} />
        <Contact />
      </HashRouter>
    </div>
  );
}

export default App;
