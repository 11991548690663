import React, { useEffect } from "react";
import "../App.css";

import screen1 from "../assets/Screenshot/screen1.jpeg";
import screen2 from "../assets/Screenshot/screen2.jpeg";
import screen3 from "../assets/Screenshot/screen3.jpeg";
import screen4 from "../assets/Screenshot/screen4.jpeg";
import screen5 from "../assets/Screenshot/screen5.jpeg";
import screen6 from "../assets/Screenshot/screen6.jpeg";
import PaymentCard from "./Components/PaymentCard";
import { TextField, Button, Divider } from "@material-ui/core";
import "./CSS/register.css";

function Home({ history }) {
  useEffect(() => {}, []);
  return (
    <div className="App">
      <div className="page1">
        {/* <div className="homeText">
          In order to subscribe; login on the right, go to account and update
          payment details and subscription
          <br />
          <br />
          Please make sure to update to the latest version on both iOS and
          Android
        </div> */}
        <div className="Screenshots">
          <img src={screen1} alt="Screenshot"></img>
          <img src={screen2} alt="Screenshot"></img>
          <img src={screen3} alt="Screenshot"></img>
          <img src={screen4} alt="Screenshot"></img>
          <img src={screen5} alt="Screenshot"></img>
          <img src={screen6} alt="Screenshot"></img>
        </div>
        <div className="homeText">
          <ul className="list">
            <li>Click banners to 'Reveal' the answer</li>
            <li>Over 300 topics covering the Final FRCA </li>
            <li>
              Over 180 Primary FRCA topics, plus every OSCE topic we could think
              of!
            </li>
          </ul>
          <ul className="list">
            <li>Test yourself with 'revision mode'</li>
            <li>Get a friend to viva you with 'viva mode'</li>
            <li>Keep a check on your progress</li>
          </ul>
        </div>
        <Divider light={false} variant="fullWidth" />
        <div className="homeText">
          <div style={{ width: "100%" }}>
            <h1>Testimonials</h1>
          </div>
          <Divider light={false} variant="fullWidth" />

          <h3>
            <br />
            Hi Reveal team, <br />
            <br />
            I sat my written and SOE Final exams this year (Sept/Dec) and am
            delighted to say that I passed both and am now FRCA-positive!
            <br />
            <br />
            I am writing to say thank you for creating such a brilliant app,
            which has helped me hugely at both stages of the exam. It really has
            the best collection of succinct, but also reliable, information I
            have found over months and months of revision (and trust me I have
            been looking at a LOT of resources of varying quality...). It's also
            fan-tastic value for money compared to lots of other courses/books
            out there - I paid for 12 months and absolutely got more than my
            money's worth as I used it most days.
            <br />
            <br />
            The viva mode is absolutely brilliant - my long-suffering,
            non-medical partner was able to ask me 100s of questions during long
            car journeys the last few weeks, and it definitely helped get my
            viva technique up to scratch. <br />
            <br />
            I have been recommending the app to anyone who will listen, so
            hopefully your fanbase continues to grow!!
            <br />
            <br />
            - Paul
            <br />
            <br />
            <Divider light={false} variant="fullWidth" />
            <br />
            <br />
            Thank you! As others will tell you, I opted to marinate myself in
            FRCA Reveal & pretty much nothing else. I owe you so much! Glad to
            hear about institutional access – the app is pure gold!
            <br />
            <br />
            - Tom
            <br />
            <br />
            <Divider light={false} variant="fullWidth" />
            <br />
            <br />I would like to say a big thank you for the efforts that went
            down in developing this app and I can confidently say that the app
            helped in my preparation & practice with my colleagues and fine tune
            my technique for the viva. I would definitely recommend FRCA Reveal
            App to anyone preparing for FRCA exams. Thank you once again for
            your help.
            <br />
            <br />- Anish
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Home;
